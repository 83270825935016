import React from 'react';
import { format } from 'date-fns';
import InputField from './InputField';
import { ReactComponent as EditIcon } from '../assets/icons/Pencil-tool.svg';
import { ReactComponent as HandshakeIcon } from '../assets/icons/Handshake.svg';
import { ReactComponent as HandIcon } from '../assets/icons/Hand.svg';
import { ReactComponent as UserIcon } from '../assets/icons/User.svg';
import { ReactComponent as HomeLocationIcon } from '../assets/icons/Home-Location.svg';
import { ReactComponent as TimeIcon } from '../assets/icons/Time.svg';

type Props = {
  assignment: any;
  handleNameChange: any;
};

export default class ConfirmationTable extends React.Component<Props, any> {
  constructor(props) {
    super(props);

    this.state = {
      editingName: false
    };
  }

  toggleEditingName = () => {
    this.setState({
      editingName: !this.state.editingName
    });
  };

  render() {
    const { assignment } = this.props;
    const services = assignment.services
      .map(function(s) {
        return s.name;
      })
      .join(', ');

    return (
      <div className="confirmation-table">
        <div className="cell">
          <HandshakeIcon className="icon" width="24" height="24" />
          <span className="text">
            {assignment.name ? assignment.name : `Toimeksianto ${assignment.locationAddress}`}
          </span>
          <div
            onClick={this.toggleEditingName}
            className="edit"
            style={{ flexGrow: 1, textAlign: 'right' }}
          >
            <EditIcon width="16" height="16" />
          </div>
        </div>

        {this.state.editingName && (
          <div
            className="cell slide"
            style={{ display: this.state.editingName ? 'block' : 'none' }}
          >
            <InputField
              id="name"
              label="Toimeksiannon nimi"
              type="text"
              onChange={this.props.handleNameChange}
              value={assignment.name}
            />
          </div>
        )}

        <div className="cell">
          <HandIcon className="icon" width="24" height="24" />
          <span className="text">{services}</span>
        </div>

        <div className="cell">
          <UserIcon className="icon" width="24" height="24" />
          <span className="text">{assignment.provider && assignment.provider.name}</span>
        </div>

        <div className="cell">
          <HomeLocationIcon className="icon" width="24" height="24" />
          <span className="text">
            {assignment.locationAddress &&
              assignment.locationPostnumber &&
              assignment.locationCity &&
              `${assignment.locationAddress}, ${assignment.locationPostnumber} ${assignment.locationCity}`}
          </span>
        </div>

        <div className="cell">
          <TimeIcon className="icon" width="24" height="24" />
          <span className="text">
            {assignment.time &&
              `${format(assignment.time, 'dd.MM.yyyy')} klo ${format(assignment.time, 'HH:mm')}`}
          </span>
        </div>
      </div>
    );
  }
}
