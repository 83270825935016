import React from 'react';

type Props = {
  assignment: any; // TODO Create typings for assignment object
  onClick: any;
};

enum StatusClass {
  Tilattu = 'Tilattu',
  Vastaanotettu = 'success',
  Tehty = 'primary',
  Hyväksytty = 'primary',
  Laskutettu = 'primary',
  Arkistoitu = 'primary'
}

enum StatusName {
  Tilattu = 'Tilattu',
  Vastaanotettu = 'Vastaanotettu',
  Tehty = 'Tehty',
  Hyväksytty = 'Hyväksytty',
  Laskutettu = 'Laskutettu',
  Arkistoitu = 'Arkistoitu'
}

export default class AssignmentListItem extends React.Component<Props, any> {
  render() {
    const { assignment } = this.props;

    if (!assignment) {
      return null;
    }

    const providerName = `${assignment.provider.firstname} ${assignment.provider.lastname}`;

    return (
      <div
        className="bondr-card row no-gutters p-2 mb-3"
        onClick={this.props.onClick(assignment.id)}
      >
        <div className="col-3">
          <div className="pic" style={{ height: 105, backgroundColor: '#E6E7EB' }}></div>
        </div>
        <div className="col-9 content p-2 pl-3">
          <h3 className="title">{assignment.name}</h3>
          <div className="name mb-2">{providerName}</div>
          <span className={`tag ${StatusClass[assignment.status]}`}>
            {StatusName[assignment.status]}
          </span>
        </div>
      </div>
    );
  }
}
