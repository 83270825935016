import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  link: string;
}

const StatusBar: React.SFC<Props> = props => {
  return (
    <div className="statusbar">
      <div>
        <Link to={props.link}>&laquo; Takaisin</Link>
      </div>
    </div>
  );
};

export default StatusBar;
