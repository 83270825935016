import React from 'react';
import { Invoice } from '../../Api';
import InvoiceItem from '../../components/Invoice';
import { withRouter } from 'react-router-dom';

class ListInvoices extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      invoices: undefined,
      loading: true
    };
  }

  componentDidMount = async () => {
    try {
      const invoices = await Invoice.getAll();
      this.setState({
        invoices: invoices,
        loading: false
      });
    } catch (e) {}
  };

  openInvoice = id => evt => {
    this.props.history.push(`/laskut/${id}`);
  };

  render() {
    const { invoices, loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className="container mt-5">
        <h1 className="mb-5">Laskut</h1>

        <div className="content">
          <div className="tagline mb-3">
            {invoices.length === 1 ? '1 lasku' : `${invoices.length} laskua`}
          </div>

          {invoices.map(invoice => (
            <InvoiceItem invoice={invoice} onClick={this.openInvoice(invoice.id)} />
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(ListInvoices);
