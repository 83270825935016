import { observable, action, runInAction } from 'mobx';
import { Organization } from '../Api';

export class OrganizationStore {
  @observable organizations: any;
  @observable inProgress = false;

  createOrganization(organization, admin) {
    this.inProgress = true;
    Organization.create(organization, admin)
      .then(response => {
        this.inProgress = false;
        return response;
      })
      .catch(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action
  async getAllOrganizations() {
    this.organizations = [];
    this.inProgress = true;
    try {
      const organizations = await Organization.getAll();
      runInAction(() => {
        this.organizations = organizations;
        this.inProgress = false;
      });
    } catch (error) {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }
}

export default new OrganizationStore();
