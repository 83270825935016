import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import FrontpageBanner from '../components/FrontpageBanner';
import AssignmentListItem from '../components/AssignmentListItem';
import AssignmentStore from '../stores/Assignment.store';

@observer
class IndexView extends React.Component<RouteComponentProps> {
  constructor(props) {
    super(props);

    AssignmentStore.getAssignments();
  }

  goToItem = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.history.push(`/toimeksianto/${id}`);
  };

  render() {
    const assignments = AssignmentStore.assignments;

    return (
      <div>
        <FrontpageBanner />
        <div className="container mb-4">
          <h1>Omat toimeksiannot</h1>
        </div>

        <div className="bg-dark">
          <div className="container">
            <div className="row pt-4 pb-4">
              {!AssignmentStore.inProgress &&
                assignments &&
                assignments.map(item => (
                  <div key={item.id} className="col-xs-12 col-md-4">
                    <AssignmentListItem key={item.id} assignment={item} onClick={this.goToItem} />
                  </div>
                ))}

              {!AssignmentStore.inProgress && assignments.length === 0 && (
                <div className="col-xs-12 col-md-4">
                  <b>Ei avoimia toimeksiantoja</b>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(IndexView);
