import * as React from 'react';

interface Props {
  id: string;
  disabled: boolean;
  onChange: any;
  value: any;
  checked: boolean;
}

export default class Checkbox extends React.Component<Props, {}> {
  inputElem: any = null;

  render() {
    return (
      <div className="checkbox-wrapper">
        <input {...this.props} type="checkbox" />
        <label htmlFor={this.props.id} />
      </div>
    );
  }
}
