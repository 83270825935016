import React from 'react';

type Props = {
  products: any[];
  title: string;
  onProductEdit: any;
};

export default class ProductBox extends React.Component<Props> {
  render() {
    const { products } = this.props;

    if (!products || products.length === 0) {
      return null;
    }

    return (
      <div className="product-box p-3 pt-4 pb-4 mb-3">
        <h2>{this.props.title}</h2>
        <ul className="list-group styled">
          {products &&
            products.map(product => (
              <li
                key={product.id}
                onClick={this.props.onProductEdit(product)}
                className="list-group-item small"
              >
                {product.name} <br />
                <small className="text-múted">{product.price}€</small>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
