import React from 'react';
import { observer } from 'mobx-react';
import InputField from '../components/InputField';
import { Auth } from '../Api';
import AlertStore from '../stores/Alert.store';
import { RouteComponentProps } from 'react-router-dom';

type State = {
  password: string;
  password2: string;
  user: any;
  loading: boolean;
};

type RouteParams = {
  token: string;
};

type Props = RouteComponentProps<RouteParams>;

@observer
class PasswordView extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password2: '',
      user: {},
      loading: false
    };
  }

  componentDidMount = async () => {
    try {
      const user = await Auth.findByPasswordToken(this.props.match.params.token);

      if (!user) {
        throw Error();
      }

      this.setState({
        user
      });
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: 'Linkkisi on virheellinen tai vanhentunut'
      });
      this.props.history.push('/kirjaudu');
    }
  };

  handleChange = key => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [key]: event.target.value
    } as State);
  };

  handleSubmit = () => {
    try {
      Auth.setPasswordWithToken(
        this.props.match.params.token,
        this.state.password,
        this.state.password2
      ).then(() => {
        this.props.history.push('/kirjaudu');
        AlertStore.addAlert({
          type: 'success',
          message: `Salasana vaihdettu. Voit nyt kirjautua sisään sähköpostilla ${this.state.user.email}`
        });
      });
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: 'Virhe salasanan asettamisessa. Yritä uudestaan'
      });
    }
  };

  render() {
    const { loading } = this.state;
    const tooShort = this.state.password && this.state.password.length < 6;
    const noMatch = !tooShort && this.state.password !== this.state.password2;

    return (
      <div className="login">
        <h1 className="plain text-center">Aseta salasana</h1>
        <p className="pl-4 pr-4">
          Tervetuloa! Aseta salasana käyttäjällesi, niin pääset jatkamaan sovellukseen
        </p>
        <form className="full-width text-center" onSubmit={this.handleSubmit}>
          <InputField
            id="password-repeat"
            label="Salasana"
            type="password"
            onChange={this.handleChange('password')}
            value={this.state.password}
            disabled={loading}
          />
          <InputField
            id="password"
            label="Toista salasana"
            type="password"
            onChange={this.handleChange('password2')}
            value={this.state.password2}
            disabled={loading}
          />

          {!!noMatch && <span className="text-danger">Salasanat eivät täsmää.&nbsp;</span>}
          {!!tooShort && <span className="text-danger">Salasanasi on liian lyhyt.&nbsp;</span>}

          <button
            className="btn btn-primary mt-3"
            disabled={!this.state.password || noMatch || tooShort || loading}
          >
            Aseta salasana
          </button>
        </form>
      </div>
    );
  }
}

export default PasswordView;
