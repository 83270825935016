import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Portfolio, User } from '../../Api';
import UserStore from '../../stores/User.store';
import TextArea from '../../components/TextArea';
import StatusBar from '../../components/StatusBar';
import AlertStore from '../../stores/Alert.store';

interface RouteParams {
  id: string;
}

class EditPortfolio extends React.Component<RouteComponentProps<RouteParams>, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDropZone: false,
      uploading: false,
      selectedItems: []
    };
  }

  componentDidMount = async () => {
    await this.refresh();
  };

  refresh = async () => {
    try {
      this.setState({
        loading: true
      });

      const user = await User.getUserWithPortfolio(UserStore.userData.id);

      this.setState({
        user,
        loading: false
      });
    } catch (e) {}
  };

  handleDescriptionChange = evt => {
    const value = evt.target.value;

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        portfolio: {
          ...prevState.user.portfolio,
          description: value
        }
      }
    }));
  };

  handleImageUpload = async (acceptedFiles, rejectedFiles, evt) => {
    /* for (const file of acceptedFiles) {
      let formData = new FormData();
      formData.append('file', file);
      try {
        Portfolio.upload(formData);
      } catch (e) {
        console.error(e);
      }
    } */

    try {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.addEventListener('loadend', async () => {
        await Portfolio.upload(
          this.state.user.portfolio.id,
          file.name,
          reader.result as ArrayBuffer
        );

        AlertStore.addAlert({
          type: 'success',
          message: `${file.name} lisätty`
        });

        this.refresh();
      });
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: `Virhe kuvan lisäämisessä: ${e}`
      });
    }
  };

  handleImageDelete = async () => {
    const itemsToDelete = this.state.selectedItems;

    try {
      await Portfolio.delete(itemsToDelete);

      AlertStore.addAlert({
        type: 'success',
        message:
          itemsToDelete.length > 1 ? `${itemsToDelete.length} kuvaa poistettu` : '1 kuva poistettu'
      });

      this.setState({
        selectedItems: []
      });

      this.refresh();
    } catch (e) {}
  };

  handleItemSelect = id => evt => {
    if (this.state.selectedItems.some(i => i === id)) {
      this.setState(prevState => ({
        selectedItems: prevState.selectedItems.filter(i => i !== id)
      }));
    } else {
      this.setState(prevState => ({
        selectedItems: [...prevState.selectedItems, id]
      }));
    }
  };

  handleDescriptionSave = async () => {
    try {
      const description = this.state.user.portfolio.description;

      await Portfolio.save(description);

      AlertStore.addAlert({
        type: 'success',
        message: 'Portfolion kuvaus tallennettu'
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  toggleDropZone = () => {
    this.setState(prevState => ({
      showDropZone: !prevState.showDropZone
    }));
  };

  render() {
    const { user, showDropZone, loading, selectedItems } = this.state;
    const maxSize = 5242880;

    if (loading) {
      return null;
    }

    return (
      <div className="portfolio edit-view">
        <StatusBar link="/portfolio" />
        <div className="container mt-5">
          <h1 className="mb-5">Portfolion muokkaus</h1>

          <h2>Oma kuvaus</h2>
          <TextArea
            id="description"
            onChange={this.handleDescriptionChange}
            value={user.portfolio.description || ''}
          />

          <button className="btn btn-primary small mb-3" onClick={this.handleDescriptionSave}>
            Tallenna
          </button>

          <div className="mb-3">
            <div className="row">
              <div className="col-6">
                <h2>Kuvat</h2>

                <span className="link d-block mb-4" onClick={this.toggleDropZone}>
                  Lisää uusi kuva
                </span>
              </div>

              <div className="col-6">
                {selectedItems.length > 0 && (
                  <div className="text-center">
                    <button className="btn btn-danger small mb-3" onClick={this.handleImageDelete}>
                      Poista {selectedItems.length > 1 ? `${selectedItems.length} kuvaa` : '1 kuva'}
                    </button>
                  </div>
                )}
              </div>
            </div>

            {showDropZone && (
              <Dropzone
                accept="image/jpg, image/jpeg, image/png"
                minSize={0}
                maxSize={maxSize}
                onDrop={this.handleImageUpload}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => {
                  return (
                    <section className={classNames('dropzone mt-3 mb-3', { active: isDragActive })}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <span>Klikkaa tai pudota tiedosto tähän</span>
                        {isDragReject && (
                          <span className="d-block error">
                            Virhe: Kuvan täytyy olla jpg- tai png-muodossa
                          </span>
                        )}
                      </div>
                    </section>
                  );
                }}
              </Dropzone>
            )}

            <div className="mb-5">
              <div className="gallery row pt-3">
                {user.portfolio.items && user.portfolio.items.length === 0 && (
                  <div className="col-12">
                    <small className="text-muted">Ei kuvia</small>
                  </div>
                )}
                {user.portfolio.items &&
                  user.portfolio.items
                    .filter(item => item.type === 'Photo')
                    .map(photo => (
                      <div key={photo.id} className="col-3 mb-3">
                        <img
                          alt=""
                          className={selectedItems.some(i => i === photo.id) ? 'active' : ''}
                          src={`${process.env.REACT_APP_API_URL}/images/${photo.thumbnailPath}`}
                          onClick={this.handleItemSelect(photo.id)}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>

          <h2>Videot</h2>
        </div>
      </div>
    );
  }
}

export default withRouter(EditPortfolio);
