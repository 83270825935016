import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import UserStore from '../../stores/User.store';

enum Roles {
  'Admin',
  'Valokuvaaja',
  'Kiinteistövälittäjä'
}

@observer
export default class ListUsers extends React.Component {
  constructor(props) {
    super(props);

    UserStore.getAllUsers();
  }

  render() {
    const users = UserStore.users;

    return (
      <div className="container mt-5">
        <h1 className="mb-3 d-block">Käyttäjät</h1>

        <ul className="list-group">
          {users.map(u => (
            <Link to={`/kayttajat/${u.id}`}>
              <li key={u.id} className="list-group-item">
                {`${u.firstname} ${u.lastname}`}{' '}
                <span className="badge badge-secondary">{Roles[u.roles[0].id - 1]}</span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    );
  }
}
