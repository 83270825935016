import React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Assignment } from '../../Api';
import { ReactComponent as HandIcon } from '../../assets/icons/Hand.svg';
import { ReactComponent as HomeLocationIcon } from '../../assets/icons/Home-Location.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/Time.svg';
import TextArea from '../../components/TextArea';
import EventItem from '../../components/EventItem';
import UserStore from '../../stores/User.store';
import AlertStore from '../../stores/Alert.store';
import InputField from '../../components/InputField';

type RouteParams = {
  id: string;
};

type Props = RouteComponentProps<RouteParams>;

type State = {
  assignment: any;
  msg: string;
};

@observer
class ViewAssignment extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      assignment: undefined,
      msg: ''
    };
  }

  async componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const id = this.props.match.params.id;

    try {
      const assignment = await Assignment.get(id);
      this.setState({
        assignment
      });
    } catch (err) {}
  };

  onShareLinkChange = evt => {
    const sharingLink = evt.target.value;
    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        sharingLink
      }
    }));
  };

  validateSharingLink = () => {
    const link = this.state.assignment.sharingLink;
    return link.length < 10;
  };

  onMsgChange = evt => {
    this.setState({
      msg: evt.target.value
    });
  };

  sendMsg = async evt => {
    evt.preventDefault();

    try {
      const msg = this.state.msg;
      await Assignment.sendMessage(this.props.match.params.id, msg);

      this.setState({
        msg: ''
      });

      this.refresh();
    } catch (e) {}
  };

  setCompleted = async evt => {
    evt.preventDefault();

    try {
      await Assignment.setCompleted(this.props.match.params.id, this.state.assignment.sharingLink);
      this.refresh();

      AlertStore.addAlert({
        type: 'success',
        message: 'Toimeksianto päivitetty'
      });
    } catch (e) {}
  };

  setAccepted = async evt => {
    evt.preventDefault();

    try {
      await Assignment.setAccepted(this.props.match.params.id);
      this.refresh();
    } catch (e) {}
  };

  render() {
    const { assignment } = this.state;

    if (!assignment) {
      return null;
    }

    return (
      <>
        <div className="statusbar">
          <div>
            <Link to="/">&laquo; Takaisin</Link>
          </div>
          <div>
            <span className="tag small success">{assignment.status}</span>
          </div>
        </div>
        <div className="container pt-5">
          <h1 className="mb-5">{assignment.name}</h1>

          <div className="bondr-card row no-gutters p-2 mb-2">
            <div className="col-3">
              <div className="pic" style={{ height: 105, backgroundColor: '#E6E7EB' }}></div>
            </div>
            <div className="col-9 content p-2 pl-3">
              <div className="mb-3">
                <h3 className="title mb-0">{assignment.user.name}</h3>
                <small className="text-muted">Kiinteistövälittäjä</small>
              </div>
              <span className="tag dark">
                <a href={`tel:${assignment.user.phone}`}>{assignment.user.phone}</a>
              </span>
            </div>
          </div>

          <div className="bondr-card row no-gutters p-2 mb-4">
            <div className="col-3">
              <div className="pic" style={{ height: 105, backgroundColor: '#E6E7EB' }}></div>
            </div>
            <div className="col-9 content p-2 pl-3">
              <div className="mb-3">
                <h3 className="title mb-0">{assignment.provider.name}</h3>
                <small className="text-muted">Palveluntuottaja</small>
              </div>
              <span className="tag dark">
                <a href={`tel:${assignment.provider.phone}`}>{assignment.provider.phone}</a>
              </span>
            </div>
          </div>

          <h2 className="mb-3">Tilatut palvelut</h2>

          <div className="confirmation-table">
            <div className="cell">
              <HandIcon className="icon" width="20" height="20" />
              <span className="text">{assignment.services}</span>
            </div>

            <div className="cell">
              <HomeLocationIcon className="icon" width="20" height="20" />
              <span className="text">
                {assignment.address}, {assignment.postnumber} {assignment.city}
              </span>
            </div>

            <div className="cell">
              <TimeIcon className="icon" width="20" height="20" />
              <span className="text">
                {format(new Date(assignment.datetime), 'd.M.yyyy hh:mm')}
              </span>
            </div>
          </div>

          <hr />

          {assignment.status === 'Tehty' && assignment.sharingLink && UserStore.isAgent && (
            <div className="text-center">
              <button className="btn btn-primary mb-4">
                <a href={assignment.sharingLink} target="_blank" rel="noopener noreferrer">
                  Katso valokuvia
                </a>
              </button>
            </div>
          )}

          {assignment.status === 'Vastaanotettu' && UserStore.isProvider && (
            <>
              <InputField
                id="share-link"
                label="Valokuvien linkki"
                type="text"
                onChange={this.onShareLinkChange}
                value={assignment.sharingLink}
              />
              <button
                onClick={this.setCompleted}
                disabled={this.validateSharingLink()}
                className="btn btn-primary mb-4"
              >
                Merkitse tehdyksi
              </button>
            </>
          )}

          {assignment.status === 'Tehty' && assignment.sharingLink && UserStore.isProvider && (
            <>
              <InputField
                id="share-link"
                label="Valokuvien linkki"
                type="text"
                onChange={this.onShareLinkChange}
                value={assignment.sharingLink}
              />
              <button
                onClick={this.setCompleted}
                disabled={this.validateSharingLink()}
                className="btn btn-primary mb-4"
              >
                Päivitä
              </button>
            </>
          )}

          {assignment.status === 'Tehty' && UserStore.isAgent && (
            <div className="text-center">
              <button onClick={this.setAccepted} className="btn btn-primary mb-4">
                Merkitse hyväksytyksi
              </button>
            </div>
          )}

          <h2 className="mb-3">Tapahtumahistoria</h2>

          {assignment.events.map(event => (
            <EventItem key={event.id} event={event} />
          ))}

          <hr />

          <TextArea
            id="additionalinfo"
            label="Lähetä viesti"
            onChange={this.onMsgChange}
            value={this.state.msg}
          />

          <button onClick={this.sendMsg} className="btn btn-primary">
            Lähetä viesti
          </button>
        </div>
      </>
    );
  }
}

export default withRouter(ViewAssignment);
