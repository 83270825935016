import React from 'react';

interface Props {
  text?: string;
}

const LoadingSpinner: React.SFC<Props> = props => {
  return (
    <div className="loader-container flex-center">
      <div className="loader"></div>
      <div className="text">{props.text}</div>
    </div>
  );
};

export default LoadingSpinner;
