import React from 'react';
import classNames from 'classnames';

type Props = {
  id: string;
  label?: string;
  value?: string | number;
  onChange: any;
  disabled?: boolean;
};

type State = {
  focus: boolean;
};

export default class TextArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false
    };
  }

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { id, label, value, disabled } = this.props;
    const rootClasses = classNames('form-group', {
      shrink: this.state.focus || !!this.props.value
    });

    return (
      <div className={rootClasses}>
        <label htmlFor={id}>{label}</label>
        <div className="input-root">
          <textarea
            className="form-control"
            id={id}
            value={value}
            disabled={disabled}
            onChange={this.props.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
        </div>
      </div>
    );
  }
}
