import { observable, action, computed, runInAction } from 'mobx';
import { User } from '../Api';

export class UserStore {
  @observable userDataInProgress = false;
  @observable inProgress = false;
  @observable userData: any;
  @observable userRoles: any;
  @observable providers: any;
  @observable search: any;
  @observable users: any;

  private getUserdataPromise!: Promise<any>;
  private getProvidersPromise!: Promise<any>;

  @action
  getUserData(): Promise<any> {
    if (this.userDataInProgress || this.userData) {
      return this.getUserdataPromise;
    }

    return this.updateUserData();
  }

  updateUserData(): Promise<any> {
    if (this.userDataInProgress) {
      return this.getUserdataPromise;
    }

    this.userDataInProgress = true;
    this.getUserdataPromise = User.getUserdata()
      .then(response => {
        this.userData = response;
        this.userDataInProgress = false;
        return response;
      })
      .catch(
        action(() => {
          this.userDataInProgress = false;
        })
      );

    return this.getUserdataPromise;
  }

  @action
  async getAllUsers() {
    this.users = [];
    this.inProgress = true;
    try {
      const users = await User.getAll();
      runInAction(() => {
        this.users = users;
        this.inProgress = false;
      });
    } catch (error) {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  @action
  getProviders(): Promise<any> {
    if (this.providers) {
      return this.getProvidersPromise;
    }

    this.inProgress = true;
    this.getProvidersPromise = User.getProviders()
      .then(response => {
        this.providers = response;
        this.inProgress = false;
        return response;
      })
      .catch(
        action(() => {
          this.inProgress = false;
        })
      );

    return this.getProvidersPromise;
  }

  @action
  getAvailableProviders(services, dateTime, address, force?: boolean): Promise<any> {
    if (this.providers) {
      //return this.getProvidersPromise;
    }

    this.inProgress = true;
    this.getProvidersPromise = User.getAvailableProviders(services, dateTime, address)
      .then(response => {
        this.providers = response;
        this.inProgress = false;
        return response;
      })
      .catch(
        action(() => {
          this.inProgress = false;
        })
      );

    return this.getProvidersPromise;
  }

  @computed
  get isAdmin(): boolean {
    return this.userData && this.userData.roles.some(role => role === 'admin');
  }

  @computed
  get isAgent(): boolean {
    return (
      this.userData &&
      (this.userData.roles.some(role => role === 'agent') ||
        this.userData.roles.some(role => role === 'admin'))
    );
  }

  @computed
  get isProvider(): boolean {
    return this.userData && this.userData.roles.some(role => role === 'provider');
  }
}

export default new UserStore();
