import React from 'react';

type Props = {
  tabs: any[];
  activeTab: number;
  onTabClick: any;
};

export default class TabBar extends React.Component<Props, any> {
  render() {
    return (
      <div className="tab-bar">
        <ul className="container nav nav-tabs">
          {this.props.tabs.map(tab => (
            <li
              key={tab.id}
              onClick={this.props.onTabClick(tab.id)}
              className={`tab-item ${this.props.activeTab === tab.id ? 'active' : ''}`}
            >
              <span key={tab.id}>{tab.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
