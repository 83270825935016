import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import TabBar from '../../components/TabBar';
import InputField from '../../components/InputField';
import ConfirmationTable from '../../components/ConfirmationTable';
import AlertStore from '../../stores/Alert.store';
import UserStore from '../../stores/User.store';
import TextArea from '../../components/TextArea';
import AddressAutocompleteField from '../../components/AddressAutocompleteFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import Calendar from '../../components/calendar/Calendar';
import { User, Assignment } from '../../Api';
import { ReactComponent as CameraIcon } from '../../assets/icons/Camera-1.svg';
import { ReactComponent as VideoCameraIcon } from '../../assets/icons/Video-Camera-2.svg';
import { ReactComponent as BalloonIcon } from '../../assets/icons/Air-balloon.svg';
import { ReactComponent as MatterportIcon } from '../../assets/icons/3D-Box.svg';
import { ReactComponent as BirthdayDecorationIcon } from '../../assets/icons/BirthdayDecoration.svg';
import { ReactComponent as LampIcon } from '../../assets/icons/Lamp-1.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/RightArrow.svg';
import { ProductType, ServiceType } from '../../util/enum';
import DateTimePicker from '../../components/DateTimePicker';
import RadioButton from '../../components/input/RadioButton';
import Checkbox from '../../components/input/Checkbox';

type State = {
  activeTab: number;
  availableProviders: any[];
  availableProducts: any[];
  assignment: {
    name: string;
    services: any;
    locationAddress: string;
    locationArea?: number;
    locationPostnumber: string;
    locationCity: string;
    provider?: {
      id: number;
      name: string;
      freeTimes?: any[];
    };
    date?: string;
    time?: string;
    additionalInfo: string;
    products: any;
  };
  loading: boolean;
  openCalendar: boolean;
  openAddressForm: boolean;
  serviceType?: number;
  calculateWithStylist: boolean;
  calculateWithDifficulty: boolean;
  useFavourites: boolean;
  favourites: any[];
};

const tabs = [
  { id: 0, name: 'Palvelut' },
  { id: 1, name: 'Palveluntuottaja' },
  { id: 2, name: 'Vahvista' }
];

// Services are hardcoded for now, but might be later moved to actual DB
const availableServices = [
  {
    id: 0,
    type: ProductType.Photography,
    name: 'Valokuvaus',
    icon: <CameraIcon width="43" height="36" />,
    serviceType: ServiceType.Photography
  },
  {
    id: 1,
    type: ProductType.Video,
    name: 'Videokuvaus',
    icon: <VideoCameraIcon width="43" height="36" />,
    serviceType: ServiceType.Photography
  },
  {
    id: 2,
    type: ProductType.AirPhotography,
    name: 'Ilmakuvaus',
    icon: <BalloonIcon width="43" height="36" />,
    serviceType: ServiceType.Photography
  },
  {
    id: 3,
    type: ProductType.MatterPort,
    name: 'Matterport',
    icon: <MatterportIcon width="43" height="36" />,
    serviceType: ServiceType.Photography
  },
  {
    id: 4,
    type: ProductType.Styling,
    name: 'Tekstiilistailaus',
    icon: <BirthdayDecorationIcon width="43" height="36" />,
    serviceType: ServiceType.Styling
  },
  {
    id: 5,
    type: ProductType.Styling,
    name: 'Kalustestailaus',
    icon: <LampIcon width="43" height="36" />,
    serviceType: ServiceType.Styling
  }
];

@observer
class NewAssignment extends React.Component<RouteComponentProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      availableProviders: [],
      availableProducts: [],
      assignment: {
        name: '',
        services: [],
        locationAddress: '',
        locationPostnumber: '',
        locationCity: '',
        locationArea: undefined,
        additionalInfo: '',
        products: []
      },
      loading: false,
      openCalendar: false,
      openAddressForm: false,
      serviceType: undefined,
      calculateWithStylist: false,
      calculateWithDifficulty: false,
      useFavourites: false,
      favourites: []
    };
  }

  selectServiceType = type => evt => {
    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        services: []
      },
      serviceType: type
    }));
  };

  toggleService = (service: any) => (event: React.MouseEvent<HTMLElement>) => {
    let services = this.state.assignment.services;

    const inArray = !!services.find(s => s.id === service.id);

    if (inArray) {
      services = services.filter(s => s.id !== service.id);
    } else {
      services = services.concat([service]);
    }

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        services
      }
    }));
  };

  toggleProduct = (product: any) => (event: React.MouseEvent<HTMLElement>) => {
    let products = this.state.assignment.products;

    const inArray = !!products.find(s => s.id === product.id);

    if (inArray) {
      products = products.filter(s => s.id !== product.id);
    } else {
      products = products.concat([product]);
    }

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        products
      }
    }));
  };

  selectProvider = provider => async (event: React.MouseEvent<HTMLElement>) => {
    const freeTimes = await User.getFreeTimes(provider.id);

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        provider: {
          id: provider.id,
          name: `${provider.firstname} ${provider.lastname}`,
          freeTimes
        }
      },
      openCalendar: true
    }));
  };

  deSelectProvider = (event: React.MouseEvent<HTMLElement>) => {
    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        provider: undefined
      },
      openCalendar: false
    }));
  };

  selectProviderWithTime = provider => async (event: React.MouseEvent<HTMLElement>) => {
    const products = await User.getProducts(provider && provider.id);

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        provider: {
          id: provider.id,
          name: `${provider.firstname} ${provider.lastname}`
        }
      },
      availableProducts: products,
      activeTab: 2
    }));
  };

  selectTime = async time => {
    const products = await User.getProducts(
      this.state.assignment.provider && this.state.assignment.provider.id
    );

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        time
      },
      availableProducts: products,
      openCalendar: false,
      activeTab: 2
    }));
  };

  handleChange = (key: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        [key]: value
      }
    }));
  };

  handleCheckboxChange = (key: 'calculateWithStylist' | 'calculateWithDifficulty') => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  setAddress = place => {
    if (!place || !place.address_components) {
      return;
    }

    const streetNumber = place.address_components.find(
      addressComponent => addressComponent.types[0] === 'street_number'
    );
    const route = place.address_components.find(
      addressComponent => addressComponent.types[0] === 'route'
    );
    const postNumber = place.address_components.find(
      addressComponent => addressComponent.types[0] === 'postal_code'
    );
    const city = place.address_components.find(
      addressComponent =>
        addressComponent.types[0] === 'locality' ||
        addressComponent.types[0] === 'administrative_area_level_3'
    );

    const address = `${route && route.long_name} ${streetNumber ? streetNumber.long_name : '1'}`;

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        locationAddress: address,
        locationPostnumber: postNumber ? postNumber.long_name : '',
        locationCity: city ? city.long_name : ''
      }
    }));
  };

  goToProducerTab = useFavourites => async (event: React.MouseEvent<HTMLElement>) => {
    let favourites = [];
    if (useFavourites) {
      //UserStore.getAvailableProviders(this.state.assignment.services);

      try {
        favourites = await User.getFavourites();
      } catch (e) {}
    }

    this.setState({
      activeTab: 1,
      useFavourites,
      favourites
    });
  };

  searchProviders = dateTime => {
    UserStore.getAvailableProviders(
      this.state.assignment.services,
      dateTime,
      `${this.state.assignment.locationAddress}, ${this.state.assignment.locationCity}`
    );

    this.setState(prevState => ({
      assignment: {
        ...prevState.assignment,
        time: dateTime
      }
    }));
  };

  submit = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      await Assignment.create(this.state.assignment);
      this.reset();

      AlertStore.addAlert({
        type: 'success',
        message: 'Uusi toimeksianto luotu'
      });

      this.props.history.push(`/`);
    } catch (e) {}
  };

  reset = () => {
    this.setState({
      activeTab: 0,
      availableProviders: [],
      availableProducts: [],
      assignment: {
        name: '',
        services: [],
        locationAddress: '',
        locationPostnumber: '',
        locationCity: '',
        locationArea: undefined,
        additionalInfo: '',
        products: []
      },
      openCalendar: false,
      useFavourites: false
    });
  };

  isValidStep = stepNumber => {
    switch (stepNumber) {
      case 1:
        if (!this.state.assignment.services.length) {
          return false;
        }

        if (
          !this.state.assignment.locationAddress ||
          !this.state.assignment.locationPostnumber ||
          !this.state.assignment.locationCity
        ) {
          return false;
        }

        if (!this.state.assignment.locationArea) {
          return false;
        }

        return true;
    }
  };

  setActiveTab = tabId => (event: React.MouseEvent<HTMLElement>) => {
    if (this.state.activeTab === 1 && tabId === 0) {
      this.setState({
        activeTab: tabId
      });
    }
    if (this.state.activeTab === 2 && tabId === 1) {
      this.setState({
        activeTab: tabId
      });
    }
  };

  calculateDuration = () => {
    const area = this.state.assignment.locationArea;

    if (!area) {
      return null;
    }

    if (area <= 30) {
      return 30;
    }

    let duration = (area - 30) * 0.5 + 30;

    if (this.state.calculateWithDifficulty) {
      duration += 30;
    }

    if (this.state.calculateWithStylist) {
      duration *= 1.75;
    }

    console.log('duration', duration);

    return duration <= 60 ? 60 : Math.ceil(duration / 15) * 15;
  };

  render() {
    const { activeTab, assignment } = this.state;
    const { services, products } = assignment;

    const availableProviders = UserStore.providers;

    if (UserStore.isAgent && !UserStore.userData.phone) {
      return (
        <div className="container pt-5">
          <h1 className="mb-5">Uusi toimeksianto</h1>
          <p>Sinun täytyy syöttää puhelinnumerosi, ennen kuin pystyt luomaan toimeksiantoja.</p>
          <p>
            <Link to="/omat-tiedot">
              Voit syöttää puhelinnumerosi painamalla <b style={{ color: '#4858e9' }}>tästä</b>
            </Link>
          </p>
        </div>
      );
    }

    return (
      <div>
        <TabBar tabs={tabs} activeTab={activeTab} onTabClick={this.setActiveTab} />
        <div className="container pt-5">
          <div className={`tab ${activeTab === 0 ? 'active' : ''}`}>
            <h1 className="mb-5">Uusi toimeksianto</h1>
            <h2 className="mb-3">Tarvitsemasi palvelut</h2>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="view-prices-1"
                  name="view-prices[]"
                  value="1"
                  onChange={this.selectServiceType(ServiceType.Photography)}
                  checked={this.state.serviceType === ServiceType.Photography}
                />
                <label className="control-label" htmlFor="view-prices-1">
                  Valokuvaus
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="view-prices-0"
                  name="view-prices[]"
                  value="0"
                  onChange={this.selectServiceType(ServiceType.Styling)}
                  checked={this.state.serviceType === ServiceType.Styling}
                />
                <label className="control-label" htmlFor="view-prices-0">
                  Stailaus
                </label>
              </div>
            </div>
            <div className="services">
              {availableServices
                .filter(service => service.serviceType === this.state.serviceType)
                .map(service => {
                  const serviceClass = classNames('service-button', {
                    selected: services.some(s => s.id === service.id)
                  });

                  return (
                    <div
                      onClick={this.toggleService(service)}
                      key={service.id}
                      className={serviceClass}
                    >
                      {service.icon}
                      {service.name}
                    </div>
                  );
                })}
            </div>
            <hr />
            <h2 className="mb-3">Kohteen tiedot</h2>
            <div className="row mb-1">
              <div className="col-12">
                <AddressAutocompleteField
                  onChange={this.setAddress}
                  value={assignment.locationAddress}
                />
              </div>
              <div className="col-12">
                <hr />
              </div>
              {/* <div className="col-12">
                <InputField
                  key="address"
                  id="address"
                  label="Katuosoite"
                  type="text"
                  onChange={this.handleChange('locationAddress')}
                  value={assignment.locationAddress || ''}
                />
              </div> */}
              <div className="col-6">
                <InputField
                  key="zipcode"
                  id="zipcode"
                  label="Postinumero"
                  type="text"
                  onChange={this.handleChange('locationPostnumber')}
                  value={assignment.locationPostnumber || ''}
                />
              </div>
              <div className="col-6">
                <InputField
                  key="city"
                  id="city"
                  label="Kaupunki"
                  type="text"
                  onChange={this.handleChange('locationCity')}
                  value={assignment.locationCity || ''}
                />
              </div>
            </div>
            <InputField
              id="area"
              label="Kohteen neliömäärä"
              type="number"
              onChange={this.handleChange('locationArea')}
              value={assignment.locationArea}
            />
            <hr />
            <h2 className="mb-0">Lisätiedot</h2>
            <p className="text-mute mb-2">
              <small>Kuvausaikaan mahdollisesti vaikuttavat tekijät</small>
            </p>
            <label>
              <Checkbox
                id="with-stylist"
                disabled={false}
                onChange={this.handleCheckboxChange('calculateWithStylist')}
                value="1"
                checked={this.state.calculateWithStylist}
              />
              Valokuvauksessa on stylisti mukana
            </label>
            <br />
            <label>
              <Checkbox
                id="difficult"
                disabled={false}
                onChange={this.handleCheckboxChange('calculateWithDifficulty')}
                value="1"
                checked={this.state.calculateWithDifficulty}
              />
              Vaikeasti kuvattava kohde
            </label>
            <br />
            Ajanvarauksen kesto: {this.calculateDuration()} {this.calculateDuration() ? 'min' : ''}
            <hr />
            <h2 className="mb-3">Valitse palveluntuottaja</h2>
            {this.isValidStep(1) && (
              <ul className="list-group">
                <li
                  onClick={this.goToProducerTab(true)}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  Suosikit
                  <div className="flex-center">
                    <RightArrowIcon className="icon" width="20" height="20" />
                  </div>
                </li>
                <li
                  onClick={this.goToProducerTab(false)}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  Hae
                  <div className="flex-center">
                    <RightArrowIcon className="icon" width="20" height="20" />
                  </div>
                </li>
              </ul>
            )}
            {!this.isValidStep(1) && (
              <div className="mb-3 text-muted">
                Täytä kaikki tiedot ennen palveluntuottajan valintaa
              </div>
            )}
          </div>

          <div className={`tab ${activeTab === 1 ? 'active' : ''}`}>
            {this.state.useFavourites && (
              <>
                <h2 className="mb-3">Suosikit</h2>
                {this.state.favourites &&
                  this.state.favourites.map(provider => {
                    return (
                      <div
                        key={provider.id}
                        onClick={this.selectProvider(provider)}
                        className="bondr-card row no-gutters p-2 mb-3"
                      >
                        <i className="material-icons toggle-icon">zoom_in</i>
                        <div className="col-2">
                          <div
                            className="pic"
                            style={{ height: 75, backgroundColor: '#E6E7EB' }}
                          ></div>
                        </div>
                        <div className="col-10 content p-2 pl-3">
                          <h3 className="title">
                            {provider.firstname} {provider.lastname}
                          </h3>
                        </div>
                      </div>
                    );
                  })}

                {this.state.favourites.length === 0 && (
                  <>
                    <div className="mb-2">
                      Sinulla ei ole suosikkeja tai kukaan suosikkisi ei tarjoa valitsemaasi
                      palvelua
                    </div>
                    <br />
                    <button onClick={this.reset} className="btn btn-primary small mb-3">
                      &laquo; Takaisin
                    </button>
                  </>
                )}

                <div className={`modal ${this.state.openCalendar && 'open'}`}>
                  <div className="modal-content">
                    <i
                      onClick={this.deSelectProvider}
                      className="material-icons toggle-icon"
                      style={{ zIndex: 100 }}
                    >
                      zoom_out
                    </i>
                    <div className="bondr-card row no-gutters p-2">
                      <div className="col-2">
                        <div
                          className="pic"
                          style={{ height: 75, backgroundColor: '#E6E7EB' }}
                        ></div>
                      </div>
                      <div className="col-10 content p-2 pl-3">
                        <h3 className="title">{assignment.provider && assignment.provider.name}</h3>
                      </div>
                    </div>
                    <Calendar
                      freeTimes={
                        this.state.assignment.provider && this.state.assignment.provider.freeTimes
                      }
                      duration={this.calculateDuration()}
                      selectTime={this.selectTime}
                    />
                  </div>
                </div>
              </>
            )}

            {!this.state.useFavourites && (
              <>
                <h2 className="mb-3">Aloita syöttämällä toivottu aika</h2>

                <DateTimePicker selectTime={this.searchProviders} />

                {UserStore.inProgress && <LoadingSpinner text="Haetaan palveluntuottajia" />}

                {availableProviders && availableProviders.length === 0 && (
                  <div>
                    Valitulle ajankohdalle ei löytynyt palveluntuottajia, ketkä tarjoaisivat
                    valitsemiasi palveluita.
                  </div>
                )}

                {availableProviders &&
                  availableProviders.map(provider => {
                    return (
                      <div
                        key={provider.id}
                        onClick={this.selectProviderWithTime(provider)}
                        className="bondr-card row no-gutters p-2 mb-3"
                      >
                        <RightArrowIcon
                          className="icon toggle-icon"
                          width="20"
                          height="20"
                          style={{ top: 35, right: 15 }}
                        />
                        <div className="col-2">
                          <div
                            className="pic"
                            style={{ height: 75, backgroundColor: '#E6E7EB' }}
                          ></div>
                        </div>
                        <div className="col-10 content p-2 pl-3">
                          <h3 className="title">
                            {provider.firstname} {provider.lastname}
                            <br />
                            <small className="text-mute">{provider.distance.text}</small>
                          </h3>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>

          <div className={`tab ${activeTab === 2 ? 'active' : ''}`}>
            <h2 className="mb-3">Varauksen tiedot</h2>

            <ConfirmationTable
              assignment={this.state.assignment}
              handleNameChange={this.handleChange('name')}
            />

            <hr />

            {this.state.assignment.services.map(s => (
              <div key={s.id} className="products">
                <h2 className="mb-3">{`Valitse ${s.name.toLowerCase()}palvelut`}</h2>

                {this.state.availableProducts
                  .filter(p => p.type === s.type)
                  .map(product => {
                    const productClass = classNames('product mb-1', {
                      selected: products.some(s => s.id === product.id)
                    });
                    return (
                      <div
                        key={product.id}
                        onClick={this.toggleProduct(product)}
                        className={`${productClass}`}
                      >
                        <div className="title">{product.name}</div>
                        <div className="description">{product.description}</div>
                        <div className="price">{product.price} €</div>
                      </div>
                    );
                  })}
              </div>
            ))}

            <hr />

            <h2 className="mb-3">Lisätiedot palveluntuottajalle</h2>

            <TextArea
              id="additionalinfo"
              label="Lisätiedot palveluntuottajalle"
              onChange={this.handleChange('additionalInfo')}
              value={assignment.additionalInfo}
            />

            <div className="text-center">
              <button onClick={this.submit} className="btn btn-primary">
                Vahvista tilaus
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewAssignment);
