import * as React from 'react';

interface Props {
  id: string;
  disabled?: boolean;
  onChange: any;
  value: any;
  checked: boolean | undefined;
  name: string;
}

export default class RadioButton extends React.Component<Props, {}> {
  inputElem: any = null;

  render() {
    const { onChange, value, checked } = this.props;

    return (
      <div className="radiobutton-wrapper">
        <input
          ref={elem => {
            this.inputElem = elem;
          }}
          {...this.props}
          type="radio"
          onChange={onChange}
          checked={checked}
          value={value}
        />
        <label htmlFor={this.props.id} />
      </div>
    );
  }
}
