export const PRODUCT_TYPE_PHOTOGRAPHY = 0;
export const PRODUCT_TYPE_VIDEO = 1;
export const PRODUCT_TYPE_AIR_PHOTOGRAPHY = 2;
export const PRODUCT_TYPE_MATTERPORT = 3;
export const PRODUCT_TYPE_STYLING = 4;

const SERVICE_TYPE_PHOTOGRAPHY = 1;
const SERVICE_TYPE_STYLING = 2;

export enum ServiceType {
  Photography = SERVICE_TYPE_PHOTOGRAPHY,
  Styling = SERVICE_TYPE_STYLING
}

export enum ProductType {
  Photography = PRODUCT_TYPE_PHOTOGRAPHY,
  Video = PRODUCT_TYPE_VIDEO,
  AirPhotography = PRODUCT_TYPE_AIR_PHOTOGRAPHY,
  MatterPort = PRODUCT_TYPE_MATTERPORT,
  Styling = PRODUCT_TYPE_STYLING
}
