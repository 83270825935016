import { action, computed, observable } from 'mobx';
import UserStore from '../stores/User.store';
import { Auth } from '../Api';
import AlertStore from './Alert.store';

const LOCALSTORAGE_KEY = 'auth-token';

export class AuthStore {
  @observable inProgress = false;
  @observable token: string | null = null;
  @observable rememberMe: boolean = false;
  @observable errors: any;

  private refreshing: Promise<any> | null;

  constructor() {
    this.token = sessionStorage.getItem(LOCALSTORAGE_KEY) || localStorage.getItem(LOCALSTORAGE_KEY);
  }

  @action
  login(email: string, password: string) {
    this.inProgress = true;
    this.errors = undefined;

    Auth.login(email, password)
      .then(response => {
        this.setToken(response.token);
        this.afterSuccesfulLogin();
        this.inProgress = false;
      })
      .catch(
        action(error => {
          this.inProgress = false;
          this.errors = error;
        })
      );
  }

  @action
  googleLogin(code: string) {
    this.inProgress = true;
    this.errors = undefined;

    Auth.glogin(code)
      .then(response => {
        this.setToken(response.token);
        this.afterSuccesfulLogin();

        this.inProgress = false;
      })
      .catch(
        action(error => {
          this.inProgress = false;
          this.errors = error;
        })
      );
  }

  @action
  afterSuccesfulLogin = async () => {
    const user = await UserStore.getUserData();

    if (user.googleId && !user.settings.calendarId) {
      AlertStore.addAlert({
        id: 'alert-nocalendar',
        type: 'danger',
        message:
          'Ajanvarauskalenteria ei ole asetettu. Voit asettaa ajanvarauskalenterisi omista tiedoista',
        timeout: 30000
      });
    }

    if (!user.phone) {
      AlertStore.addAlert({
        id: 'alert-nocalendar',
        type: 'danger',
        message: 'Puhelinnumeroa ei ole asetettu. Voit syöttää puhelinnumerosi omista tiedoista',
        timeout: 30000
      });
    }

    window.scrollTo(0, 0);
  };

  @action
  logout() {
    this.token = null;
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('/');
  }

  @action
  setToken = (token: string): void => {
    this.token = token;

    if (this.rememberMe) {
      localStorage.setItem(LOCALSTORAGE_KEY, token);
    } else {
      sessionStorage.setItem(LOCALSTORAGE_KEY, token);
    }
  };

  @action
  refreshToken(): Promise<any> {
    if (!this.token) {
      throw Error('Cannot refresh token, not authenticated');
    }

    if (!this.refreshing) {
      this.refreshing = Auth.refreshToken(this.token)
        .then(
          action((response: { token: string }) => {
            this.setToken(response.token);
            this.refreshing = null;
          })
        )
        .catch(
          action(error => {
            this.refreshing = null;
          })
        );
    }

    return this.refreshing;
  }

  @computed
  get isAuthenticated(): boolean {
    return !!this.token;
  }
}

export default new AuthStore();
