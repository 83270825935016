import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import UserStore from '../stores/User.store';
import AuthStore from '../stores/Auth.store';
import { ReactComponent as AccountIcon } from '../assets/icons/Account.svg';

type State = {
  collapsed: boolean;
};

@observer
export default class Navigation extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const navbarCollapseClassnames = classNames('collapse navbar-collapse', {
      show: this.state.collapsed
    });

    return (
      <nav className="navbar">
        <div className="navbar-header">
          {AuthStore.isAuthenticated && (
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleCollapse}
              data-toggle="collapse"
              data-target="#navbarNav"
            >
              <i className="material-icons">{this.state.collapsed ? 'close' : 'menu'}</i>
            </button>
          )}

          <div className="logo">
            <Link to="/" className="unstyled">
              Bondr
            </Link>
          </div>

          {AuthStore.isAuthenticated && (
            <Link to="/omat-tiedot" className="unstyled">
              <span className="user" style={{ display: 'flex' }}>
                <span className="pr-2">{UserStore.userData && UserStore.userData.firstName}</span>
                <AccountIcon width="20" height="20" />
              </span>
            </Link>
          )}
        </div>
        <div className={navbarCollapseClassnames} id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink exact className="nav-link" onClick={this.toggleCollapse} to="/">
                Omat toimeksiannot
              </NavLink>
            </li>
            {UserStore.isAgent && (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={this.toggleCollapse}
                    to="/toimeksianto/uusi"
                  >
                    Uusi toimeksianto
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={this.toggleCollapse}
                    to="/selaa-palveluntuottajia"
                  >
                    Selaa palveluntuottajia
                  </NavLink>
                </li>
              </>
            )}

            {UserStore.isProvider && (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.toggleCollapse} to="/portfolio">
                    Portfolio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.toggleCollapse} to="/laskut">
                    Laskut
                  </NavLink>
                </li>
              </>
            )}

            {UserStore.userData && UserStore.userData.adminOf && (
              <>
                <hr />
                {UserStore.userData.adminOf.map(org => (
                  <li key={org.id} className="nav-item">
                    <NavLink
                      className="nav-link"
                      onClick={this.toggleCollapse}
                      to={`/organisaatiot/${org.id}`}
                    >
                      {org.name}
                    </NavLink>
                  </li>
                ))}
              </>
            )}

            {UserStore.isAdmin && (
              <>
                <hr />
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.toggleCollapse} to="/organisaatiot">
                    Organisaatiot
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.toggleCollapse} to="/kayttajat">
                    Käyttäjät
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.toggleCollapse} to="/tapahtumaloki">
                    Tapahtumaloki
                  </NavLink>
                </li>
              </>
            )}

            <hr />

            <li className="nav-item">
              <NavLink className="nav-link" to="/omat-tiedot" onClick={this.toggleCollapse}>
                Omat tiedot
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/kirjaudu-ulos" onClick={this.toggleCollapse}>
                Kirjaudu ulos
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
