import React from 'react';
import StatusBubble from './StatusBubble';
import ChatBubble from './ChatBubble';
import UserStore from '../stores/User.store';

type Props = {
  event: any;
};

export default class EventItem extends React.Component<Props> {
  render() {
    const { event } = this.props;

    const currentUser = UserStore.userData.id;

    if (event.userId) {
      return <ChatBubble event={event} isYours={event.userId === currentUser} />;
    } else {
      return <StatusBubble event={event} />;
    }
  }
}
