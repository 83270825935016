import React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import AlertStore from '../../stores/Alert.store';
import { Invoice } from '../../Api';
import DatePicker from 'react-date-picker';
import InputField from '../../components/InputField';

type RouteParams = {
  id: string;
};

type Props = RouteComponentProps<RouteParams>;

type State = {
  invoice: any;
  openRowEditor: boolean;
  loading: boolean;
  newRow: {
    description: string;
    amount?: number;
  };
  editingRow?: number;
};

@observer
class ViewInvoice extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      invoice: undefined,
      newRow: {
        description: '',
        amount: 0
      },
      openRowEditor: false,
      editingRow: undefined,
      loading: true
    };
  }

  async componentDidMount() {
    try {
      const invoice = await Invoice.get(this.props.match.params.id);

      this.setState({
        invoice,
        loading: false
      });
    } catch (e) {}
  }

  handleChange = property => async evt => {
    const value = evt.target.value;

    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        [property]: value
      }
    }));
  };

  handleDueDateChange = date => {
    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        dueDate: date.toISOString()
      }
    }));
  };

  handleNewRowChange = property => async evt => {
    evt.preventDefault();

    const value = evt.target.value;

    this.setState(prevState => ({
      newRow: {
        ...prevState.newRow,
        [property]: value
      }
    }));
  };

  editRow = id => evt => {};

  saveRow = () => {
    try {
      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          rows: [
            ...prevState.invoice.rows,
            {
              description: this.state.newRow.description,
              amount: this.state.newRow.amount,
              taxPercentage: 24
            }
          ]
        },
        openRowEditor: false,
        newRow: {
          description: '',
          amount: undefined
        }
      }));
    } catch (e) {
      console.log('error', e);
    }
  };

  toggleRowEditorOpen = evt => {
    evt.preventDefault();
    this.setState({
      openRowEditor: true
    });
  };

  save = async evt => {
    evt.preventDefault();
    try {
      await Invoice.update(this.state.invoice);
      AlertStore.addAlert({
        type: 'success',
        message: 'Lasku päivitetty'
      });
    } catch (e) {}
  };

  render() {
    const { invoice, loading, openRowEditor, newRow } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <div className="statusbar">
          <div>
            <Link to="/laskut">&laquo; Takaisin</Link>
          </div>
          <div>
            <span className="tag small success">{invoice.status}</span>
          </div>
        </div>
        <div className="invoice-single container mt-5">
          <h1 className="mb-5 d-block">Lasku {invoice.assignment.name}</h1>

          <div className="mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Tuote</th>
                  <th style={{ width: '20%' }}>Hinta</th>
                  <th style={{ width: '20%' }}>Hinta + alv</th>
                </tr>
              </thead>
              <tbody>
                {invoice.rows.map(row => (
                  <tr key={row.id} onClick={this.editRow(row.id)}>
                    <td>{row.description}</td>
                    <td>{row.amount} €</td>
                    <td>
                      {row.amountWithTax}€ <small>({row.taxPercentage}%</small>)
                    </td>
                  </tr>
                ))}
                {openRowEditor && (
                  <>
                    <tr className="new-invoice">
                      <td>
                        <input
                          className="description"
                          type="text"
                          step="0.01"
                          onChange={this.handleNewRowChange('description')}
                        />
                      </td>
                      <td>
                        <input
                          className="amount"
                          type="number"
                          step="0.01"
                          onChange={this.handleNewRowChange('amount')}
                        />
                      </td>
                      <td>
                        <input
                          className="amount"
                          type="number"
                          value={newRow.amount && newRow.amount * 1.24}
                          disabled
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div className="row-controls mb-3">
              {openRowEditor && (
                <button className="btn btn-primary extra-small mt-3" onClick={this.saveRow}>
                  Tallenna rivi
                </button>
              )}
              {!openRowEditor && (
                <button
                  className="btn btn-primary extra-small mt-3"
                  onClick={this.toggleRowEditorOpen}
                >
                  + Uusi rivi
                </button>
              )}
            </div>

            <hr />

            <div className="mb-3">
              <label className="label">Eräpäivä</label>
              <DatePicker
                calendarIcon={null}
                clearIcon={null}
                value={new Date(invoice.dueDate)}
                onChange={this.handleDueDateChange}
                className=""
              />
            </div>
            <div>
              <InputField
                key="referenceNumber"
                id="referenceNumber"
                label="Viitenumero"
                type="text"
                onChange={undefined}
                value={invoice.referenceNumber || ''}
                disabled
              />
            </div>
          </div>

          <button onClick={this.save} className="btn btn-primary mr-3">
            Tallenna luonnos
          </button>
          <button className="btn btn-primary">Lähetä lasku</button>
        </div>
      </>
    );
  }
}

export default withRouter(ViewInvoice);
