import React from 'react';
import classNames from 'classnames';
import Calendar from 'react-calendar';
import TimeField from 'react-simple-timefield';
import { ReactComponent as DownArrowIcon } from '../assets/icons/DownArrow.svg';
import { ReactComponent as UpArrowIcon } from '../assets/icons/UpArrow.svg';
import 'react-calendar/dist/Calendar.css';
import { format, parse } from 'date-fns';

type Props = {
  selectTime: any;
};

export default class DateTimePicker extends React.Component<Props, any> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }
  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  setDate = date => {
    this.setState({ date });
  };

  setTime = (evt, value) => {
    const newTime = value.replace(/-/g, ':');
    const time = newTime.substr(0, 5);

    this.setState({ time });
  };

  submit = () => {
    const dateTimeRaw = `${format(this.state.date, 'd.M.yyyy')} ${this.state.time}`;
    const dateTime = parse(dateTimeRaw, 'd.M.yyyy HH:mm', new Date());

    this.props.selectTime(dateTime);
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { date, time, isOpen } = this.state;

    const overlayClasses = classNames('overlay', {
      visible: isOpen
    });

    const dateTimePickerClasses = classNames('datetimepicker mb-3', {
      open: isOpen
    });

    const arrowIconProps = {
      className: 'icon',
      width: '15',
      height: '15'
    };

    const hasDateOrTime = !!date || !!time;
    const formattedDate = date ? format(date, 'd.M.yyyy') : '';

    const fieldLabel = hasDateOrTime ? (
      <>
        {formattedDate && <span className="dt-tag">{formattedDate}</span>}
        {time && <span className="dt-tag">{time}</span>}
      </>
    ) : (
      'Valitse päivämäärä ja aloitusaika'
    );

    const isValid = !!date && !!time;

    return (
      <>
        <div className={overlayClasses} />
        <div className={dateTimePickerClasses}>
          <div onClick={this.toggleOpen} className="datetimepicker-input">
            {fieldLabel}

            {isOpen ? (
              <UpArrowIcon {...arrowIconProps} style={{ float: 'right', marginTop: 2 }} />
            ) : (
              <DownArrowIcon {...arrowIconProps} style={{ float: 'right', marginTop: 2 }} />
            )}
          </div>
          {isOpen && (
            <div>
              <Calendar
                className="mb-4"
                onChange={this.setDate}
                next2Label=""
                prev2Label=""
                value={date}
              />
              <div className="lower p-2 pb-4">
                <div>
                  Aloitusaika
                  <TimeField
                    className="timepicker"
                    onChange={this.setTime}
                    value={time || '00:00'}
                    style={{
                      width: 107
                    }}
                  />
                </div>
                <button className="btn btn-primary" disabled={!isValid} onClick={this.submit}>
                  Etsi
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
