import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import fi from 'date-fns/locale/fi';
import classNames from 'classnames';

type Props = {
  isYours: boolean;
  event: any;
};

export default class ChatBubble extends React.Component<Props> {
  render() {
    const { event, isYours } = this.props;

    const chatClasses = classNames('chatbubble mb-3', {
      yours: isYours
    });

    return (
      <div className={chatClasses}>
        <p style={{ margin: 0 }}>
          <span className="text">{event.data}</span>
          <br />
          <span className="timestamp">
            {formatDistance(new Date(), new Date(event.createdAt), { locale: fi })} sitten
          </span>
        </p>
      </div>
    );
  }
}
