import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Organization } from '../../Api';
import UserStore from '../../stores/User.store';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/RightArrow.svg';
import InputField from '../../components/InputField';
import RadioButton from '../../components/input/RadioButton';
import AlertStore from '../../stores/Alert.store';

interface RouteParams {
  id: string;
}

@observer
class ViewOrganization extends React.Component<RouteComponentProps<RouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      newMember: {
        firstName: '',
        lastName: '',
        email: '',
        settings: {}
      },
      organization: {
        members: [],
        admins: []
      }
    };
  }

  async componentDidMount() {
    const organization = await Organization.findById(this.props.match.params.id);

    this.setState({
      loading: false,
      organization: {
        name: organization.name,
        members: organization.users,
        admins: organization.admins
      }
    });
  }

  handleNewUserValueChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      newMember: {
        ...prevState.newMember,
        [key]: value
      }
    }));
  };

  handleSettingsChange = key => evt => {
    const value = evt.target.value;
    this.setState(prevState => ({
      newMember: {
        ...prevState.newMember,
        settings: {
          ...prevState.newMember.settings,
          [key]: value
        }
      }
    }));
  };

  openUserEditor = () => {
    this.setState({
      openUserEditor: true
    });
  };

  addMember = async () => {
    try {
      const member: any = await Organization.addMember(
        this.props.match.params.id,
        this.state.newMember
      );

      this.setState({
        newMember: {
          firstName: '',
          lastName: '',
          email: '',
          settings: {}
        },
        openUserEditor: false
      });

      AlertStore.addAlert({
        type: 'success',
        message: `${member.firstName} ${member.lastName} lisätty organisaatioon`
      });

      console.log(member);
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: `Virhe käyttäjän lisäämisessä: ${e}`
      });
    }
  };

  render() {
    const { organization, loading, newMember } = this.state;
    const user = this.state.newMember;

    const allowEditing = UserStore.isAdmin;

    const isValidMember =
      newMember.firstName.length &&
      newMember.lastName.length &&
      newMember.email.length > 6 &&
      newMember.email.indexOf('@') !== -1 &&
      newMember.settings;

    if (loading) {
      return (
        <div className="container mt-5">
          <h2>Ladataan</h2>
        </div>
      );
    }

    console.log(organization.admins);

    return (
      <div className="container mt-5">
        <h1 className="mb-5 d-block">{organization.name}</h1>

        <button onClick={this.openUserEditor} className="btn btn-primary small mb-5">
          Lisää uusi käyttäjä
        </button>

        <div className={`modal ${this.state.openUserEditor && 'open'}`}>
          <div className="modal-content p-3">
            <h2 className="mb-3">Lisää uusi käyttäjä</h2>

            <InputField
              id="firstName"
              label="Etunimi"
              type="text"
              onChange={this.handleNewUserValueChange('firstName')}
              value={user.firstName || ''}
            />
            <InputField
              id="lastName"
              label="Sukunimi"
              type="text"
              onChange={this.handleNewUserValueChange('lastName')}
              value={user.lastName || ''}
            />
            <InputField
              id="email"
              label="Sähköposti"
              type="text"
              onChange={this.handleNewUserValueChange('email')}
              value={user.email || ''}
            />

            <p>Salli kuvaajien hintojen näkeminen</p>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="view-prices-1"
                  name="view-prices[]"
                  value="1"
                  onChange={this.handleSettingsChange('canViewPrices')}
                  checked={user.settings.canViewPrices === '1'}
                />
                <label className="control-label" htmlFor="view-prices-1">
                  Kyllä
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="view-prices-0"
                  name="view-prices[]"
                  value="0"
                  onChange={this.handleSettingsChange('canViewPrices')}
                  checked={user.settings.canViewPrices === '0'}
                />
                <label className="control-label" htmlFor="view-prices-0">
                  Ei
                </label>
              </div>
            </div>

            <p>Salli tilaaminen muilta, kuin yrityksen suosikeilta</p>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="only-favs-1"
                  name="only-favs[]"
                  value="1"
                  onChange={this.handleSettingsChange('orderOnlyFromFavourites')}
                  checked={user.settings.orderOnlyFromFavourites === '1'}
                />
                <label className="control-label" htmlFor="only-favs-1">
                  Kyllä
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="only-favs-0"
                  name="only-favs[]"
                  value="0"
                  onChange={this.handleSettingsChange('orderOnlyFromFavourites')}
                  checked={user.settings.orderOnlyFromFavourites === '0'}
                />
                <label className="control-label" htmlFor="only-favs-0">
                  Ei
                </label>
              </div>
            </div>

            <p>Salli yrityksen suosikkilistan muokkaaminen</p>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="edit-favs-1"
                  name="edit-favs[]"
                  value="1"
                  onChange={this.handleSettingsChange('canEditFavourites')}
                  checked={user.settings.canEditFavourites === '1'}
                />
                <label className="control-label" htmlFor="edit-favs-1">
                  Kyllä
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="edit-favs-0"
                  name="edit-favs[]"
                  value="0"
                  onChange={this.handleSettingsChange('canEditFavourites')}
                  checked={user.settings.canEditFavourites === '0'}
                />
                <label className="control-label" htmlFor="edit-favs-0">
                  Ei
                </label>
              </div>
            </div>

            <button
              onClick={this.addMember}
              disabled={!isValidMember}
              className="btn btn-primary small mb-5"
            >
              Lisää uusi käyttäjä
            </button>
          </div>
        </div>

        <h2 className="mb-3">Pääkäyttäjät</h2>
        <ul className="list-group mb-5">
          {organization.admins.map(member => (
            <Link to={`/kayttajat/${member.id}`}>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                {member.firstname} {member.lastname}
                {allowEditing && (
                  <span>
                    <div className="flex-center">
                      <RightArrowIcon className="icon" width="20" height="20" />
                    </div>
                  </span>
                )}
              </li>
            </Link>
          ))}
        </ul>
        <h2 className="mb-3">Käyttäjät</h2>
        <ul className="list-group">
          {organization.members.map(member => (
            <Link to={`/kayttajat/${member.id}`}>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                {member.firstname} {member.lastname}
                {allowEditing && (
                  <span>
                    <div className="flex-center">
                      <RightArrowIcon className="icon" width="20" height="20" />
                    </div>
                  </span>
                )}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(ViewOrganization);
