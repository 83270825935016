import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import classNames from 'classnames';

type Props = {
  onChange: any;
  value: any;
};

type State = {
  focus: boolean;
};

export default class AddressAutocompleteField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false
    };
  }

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const rootClasses = classNames('form-group', {
      shrink: this.state.focus || !!this.props.value
    });

    return (
      <div className={rootClasses}>
        <label htmlFor="address-autocomplete">Kohteen osoite</label>
        <div className="input-root">
          <Autocomplete
            id="address-autocomplete"
            onPlaceSelected={this.props.onChange}
            types={['address']}
            componentRestrictions={{ country: 'fi' }}
            placeholder=""
            className="form-control"
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
        </div>
      </div>
    );
  }
}
