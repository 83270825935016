import React from 'react';
import { observer } from 'mobx-react';
import { GoogleLogin } from 'react-google-login';
import InputField from '../components/InputField';
import AuthStore from '../stores/Auth.store';

type State = {
  email: string;
  password: string;
};

@observer
class LoginView extends React.Component<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };

    if (AuthStore.isAuthenticated) {
      this.props.history.push(`/`);
    }
  }

  handleChange = (key: 'email' | 'password') => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [key]: event.target.value
    } as State);
  };

  handleSubmit = async evt => {
    evt.preventDefault();

    AuthStore.login(this.state.email, this.state.password);
  };

  onGoogleLoginSuccess = (response: any) => {
    const { code } = response;
    AuthStore.googleLogin(code);
  };

  onGoogleLoginFailure = (response: any) => {
    console.log(response);
  };

  render() {
    const { inProgress, errors } = AuthStore;

    return (
      <div className="login">
        <h1 className="plain text-center">Kirjaudu sisään</h1>
        <form className="full-width text-center" onSubmit={this.handleSubmit}>
          <InputField
            id="email"
            label="Sähköposti"
            type="text"
            onChange={this.handleChange('email')}
            value={this.state.email}
            disabled={inProgress}
          />
          <InputField
            id="password"
            label="Salasana"
            type="password"
            onChange={this.handleChange('password')}
            value={this.state.password}
            disabled={inProgress}
          />

          {!!errors && <div className="error mb-2">Kirjautuminen epäonnistui.</div>}

          <button className="btn btn-primary" disabled={inProgress}>
            Kirjaudu sisään
          </button>
        </form>

        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
          buttonText="Kirjaudu sisään"
          onSuccess={this.onGoogleLoginSuccess}
          onFailure={this.onGoogleLoginFailure}
          cookiePolicy={'single_host_origin'}
          accessType="offline"
          scope="email profile openid https://www.googleapis.com/auth/calendar"
          fetchBasicProfile={false}
          responseType="code"
          prompt="consent"
        />
      </div>
    );
  }
}

export default LoginView;
