import { observable, action } from 'mobx';

export type Alert = {
  id?: string;
  type: string;
  message: string;
  dismiss?: any;
  timeout?: number;
};

export class AlertStore {
  @observable alerts = observable.array<Alert>([]);

  @action
  addAlert = (alert: Alert) => {
    const alertId =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    this.alerts.push({
      id: alertId,
      type: alert.type,
      message: alert.message
    });

    setTimeout(() => this.clearAlert(alertId), alert.timeout || 5000);
  };

  @action
  clearAlert = alertId => {
    const alerts = this.alerts.filter(alert => alert.id !== alertId);
    this.alerts.replace(alerts);
  };
}

export default new AlertStore();
