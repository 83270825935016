import { observable, action } from 'mobx';
import { Assignment } from '../Api';

export class AssignmentStore {
  @observable assignments = observable.array<any>([]);
  @observable inProgress = false;

  private getAssignmentsPromise!: Promise<any>;

  @action
  getAssignments() {
    this.inProgress = true;
    return Assignment.getAll()
      .then(
        action((response: any) => {
          // Clear observable array before pushing employments from response.
          this.assignments.clear();
          for (let i = 0; i < response.length; i++) {
            this.assignments.push(response[i]);
          }
          this.inProgress = false;
          return response;
        })
      )
      .catch(
        action(error => {
          this.inProgress = false;
        })
      );
  }
}

export default new AssignmentStore();
