import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import fi from 'date-fns/locale/fi';

type Props = {
  event: any;
};

export default class StatusBubble extends React.Component<Props> {
  getBubbleColor = () => {
    return this.props.event.data === 'Tilattu' ? '#4858e9' : '#39BC75';
  };

  render() {
    const { event } = this.props;

    return (
      <div className="status mb-3">
        <i
          className="material-icons icon-bubble"
          style={{ backgroundColor: this.getBubbleColor() }}
        >
          check
        </i>
        <p style={{ margin: 0 }}>
          <span className="text">{event.data}</span>
          <br />
          <span className="time">
            {formatDistance(new Date(), new Date(event.createdAt), { locale: fi })} sitten
          </span>
        </p>
      </div>
    );
  }
}
