import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { UserEvent } from '../Api';

@observer
class EventLogView extends React.Component<RouteComponentProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }

  componentDidMount = async () => {
    try {
      const events = await UserEvent.get();

      this.setState({
        events
      });
    } catch (e) {}
  };
  render() {
    const { events } = this.state;

    const EventTypes = {
      '0': () => 'Uusi organisaatio luotu',
      '1': event => 'Uusi käyttäjä luotu',
      '2': event => (
        <Link to={`/toimeksianto/${event.additionalInfo.assignmentId}`}>
          Uusi toimeksianto "{event.additionalInfo.assignmentName}" luotu
        </Link>
      )
    };

    return (
      <div className="container mt-5">
        <h1 className="mb-5 d-block">Tapahtumaloki</h1>
        <table className="table">
          <thead>
            <tr>
              <th>Käyttäjä</th>
              <th>Aika</th>
              <th>IP-osoite</th>
              <th>Tyyppi</th>
            </tr>
          </thead>
          <tbody>
            {events.map(event => (
              <tr key={event.id}>
                <td>{event.userId}</td>
                <td>{event.createdAt.split('T').join(' ')}</td>
                <td>{event.ip}</td>
                <td>{EventTypes[event.type](event)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(EventLogView);
